
import logo from './wiplogo.svg';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>LastAct.Life Coming Soon!</h1>
        <h3>© copyright 2021 - LastAct.Life, Inc. all rights reserved</h3>
        <h3>v3.74384834</h3>
      </header>
    </div>
  );
}

export default App;
